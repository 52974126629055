<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Declaração</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12">
          <span class="value-title"
            >Aviso de entrega de leite fora do padrão para crioscopia:</span
          >
        </v-col>
        <v-col cols="12">
          <span class="value-title">Produtor(a): </span><br />
          <span class="key-title">{{ relatorio.fazenda.produtor.Nome }}</span>
        </v-col>
        <v-col cols="12">
          <span class="value-title">Propriedade: </span><br />
          <span class="key-title">{{
            relatorio.fazenda.pessoajuridica.NomeFantasia
          }}</span>
        </v-col>
        <v-col cols="12">
          <span class="value-title">Leite coletado dia: </span><br />
          <span class="key-title">
            {{ relatorio.CrioscopiaDataRecomendacao1Text }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="value-title">Resultado da análise de crioscopia: </span>
          <br />
          <span v-if="relatorio.CrioscopiaMedida == 1" class="key-title">
            {{ relatorio.CrioscopiaConstatadaRecomendacao1Text + ' °C' }}
          </span>
          <span v-else class="key-title">
            {{ relatorio.CrioscopiaConstatadaRecomendacao2Text + ' °H' }}
          </span>
        </v-col>
        <v-col cols="12">
          <span v-if="relatorio.CrioscopiaMedida == 1" class="value-title">
            O índice de crioscópico deve estar entre -0,512°C(quinhentos e doze
            milésimos de grau Celsius negativos) e a -0,536°C (quinhentos e
            trinta e seis milésimos de grau Celsius negativos), respectivamente;
          </span>
          <span v-else class="value-title">
            O índice de crioscópico deve estar entre -0,530°H(quinhentos e
            trinta milésimos de grau Hortvet negativos) e a -0,555°H (quinhentos
            e cinquenta e cingo milésimos de grau Hortvet negativos),
            respectivamente;
          </span>
        </v-col>
        <v-col cols="12">
          <span class="value-title">
            Com este aviso, pedimos a atenção dos envolvidos no processo de
            produção de leite para que haja a melhoria de qualidade.
          </span>
        </v-col>
        <v-col cols="12">
          <span class="value-title">Resultado recebido na fazenda por: </span>
          <span class="key-title">{{ relatorio.fazenda.produtor.Nome }}</span>
        </v-col>
        <v-col cols="12" style="text-align: center">
          <img
            :src="
              relatorio.AssinaturaProdutorOuResponsavel === '1'
                ? relatorio.AssinaturaProdutor
                : relatorio.AssinaturaResponsavel
            "
            alt="Não foi assinado!!!"
            width="auto"
            height="300px"
            class="assinatura"
          />
          <span class="key-title">
            Assinatura
            {{
              relatorio.AssinaturaProdutorOuResponsavel === '2'
                ? 'do responsável'
                : 'do(a) produtor(a)'
            }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DeclaracaoEvolui',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}

.assinatura {
  display: block;
  transform: rotate(90deg);
  margin-left: auto;
  margin-right: auto;
  border: solid 2px #333;
  border-radius: 10px;
}
</style>
