import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')

  return cursor + smallBoxHeight
}

// eslint-disable-next-line no-unused-vars
function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

// eslint-disable-next-line no-unused-vars
function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  return cursor + smallBoxHeight * numberLines
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório de fraude')

  doc.text(
    'Relatório de fraude',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média do último mês:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia
      ? `${relatorio.ProducaoMedia} L / Dia`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'CPP média do último mês:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média do último mês:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Número de pessoas envolvidas no manejo de rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.NumeroMDO ? `${relatorio.NumeroMDO}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Área para produção de leite (ha):',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.AreaProducao ? `${relatorio.AreaProducao} ha` : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.text(
    'Produtividade / Área:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia && relatorio.AreaProducao
      ? `${convertNumberBR(
          (parseInt(relatorio.ProducaoMedia) * 365) /
            parseInt(relatorio.AreaProducao),
        )} L / ha / ano`
      : '-',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')
  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')
  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')
}

function composicaoRebanho(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = config

  cursor = startY + boxHeight + 180

  cursor = checkAddPage(doc, cursor, boxHeight, config)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Composição do rebanho', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasLactacao}`,
    startX + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas secas:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasSecas}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Novilhas:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.Novilhas}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Machos:',
    startX + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Machos}`,
    startX + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em recria:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Recria}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em aleitamento:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Aleitamento}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação / total de vacas:',
    startX + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacVacas(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Vacas em lactação / total do rebanho:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacRebanho(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Produtividade / vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividade(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / total de vacas:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')

  cursor += cursor + boxHeight

  return cursor + boxHeight
}

function crioscopia(doc, relatorio, config, cursor) {
  const {
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    tableLimit,
    line,
    boxHeight,
    heightLimit,
    margin,
  } = config

  if (relatorio.CrioscopiaConstatada) {
    cursor += config.boxHeight * 22.5

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Crioscopia', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.CrioscopiaConstatada == 'confirm') {
      doc.rect(startX, cursor, tableLimit, line, 'F')
      renderCheckboxList(doc, '(V) Crioscopia constatada:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.CrioscopiaMedida) {
        if (relatorio.CrioscopiaMedida == 1) {
          renderCheckboxList(
            doc,
            relatorio.CrioscopiaConstatadaRecomendacao1Text + ' °C',
            cursor,
            config,
          )
        } else {
          renderCheckboxList(
            doc,
            relatorio.CrioscopiaConstatadaRecomendacao1Text2 + ' °H',
            cursor,
            config,
          )
        }
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    } else if (relatorio.CrioscopiaConstatada != '') {
      renderCheckbox(doc, '(X) Crioscopia constatada', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.CrioscopiaData == 'confirm') {
      doc.rect(startX, cursor, tableLimit, line, 'F')
      renderCheckboxList(doc, '(V) Data da amostra:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.CrioscopiaDataRecomendacao1Text) {
        renderCheckboxList(
          doc,
          relatorio.CrioscopiaDataRecomendacao1Text,
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    } else if (relatorio.CrioscopiaData != '') {
      renderCheckbox(doc, '(X) Data da amostra', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.AguaTubulacao == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Há presença de água na tubulação ou em alguma parte do equipamento antes de iniciar a ordenha?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AguaTubulacaoRecomendacao1) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

        renderCheckboxList(
          doc,
          'Esgotar toda a água do sistema antes de iniciar a ordenha',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
    } else if (relatorio.AguaTubulacao != '') {
      renderCheckbox(
        doc,
        '(X) Há presença de água na tubulação ou em alguma parte do equipamento antes de iniciar a ordenha?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.EnxagueDescartado == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O primeiro enxágue após a ordenha é descartado fora do tanque?',
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.EnxagueDescartado != '') {
      renderCheckbox(
        doc,
        '(X) O primeiro enxágue após a ordenha é descartado fora do tanque?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      if (relatorio.EnxagueDescartadoRecomendacao1) {
        renderCheckboxList(
          doc,
          'Todo o resíduo de enxágue deve ser descartado',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.CongelamentoLeite == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Funcionamento do tanque está adequado?',
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.CongelamentoLeite != '') {
      renderCheckbox(
        doc,
        '(X) Funcionamento do tanque está adequado?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      if (relatorio.CongelamentoLeiteRecomendacao1) {
        renderCheckboxList(
          doc,
          'Entrar em contato com o técnico responsável pela manutenção do tanque',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.DietaBalanceadaRebanho == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A dieta do rebanho está balanceada?',
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.DietaBalanceadaRebanho != '') {
      renderCheckbox(
        doc,
        '(X) A dieta do rebanho está balanceada?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      if (relatorio.DietaBalanceadaRebanhoRecomendacao1) {
        renderCheckboxList(
          doc,
          'Entrar em contato com profissional capacitado para ajustar a dieta',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    cursor += smallBoxHeight
  }

  return cursor
}

function declaracao(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  var colInicio = startX
  var data
  var numberLines
  const produtor = new Image()

  cursor += config.boxHeight
  if (cursor + boxHeight * 8 > heightLimit) {
    doc.addPage()
    cursor = margin
  }
  if (relatorio.AssinaturaProdutorOuResponsavel) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Declaração', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5
    doc.rect(startX, cursor, tableLimit, line, 'F')

    renderCheckboxList(
      doc,
      'Aviso de entrega de leite fora do padrão para crioscopia:',
      cursor,
      config,
    )
    cursor += smallBoxHeight

    renderCheckboxList(doc, 'Produtor(a): ', cursor, config)
    cursor += smallGutterY

    doc.setFont('helvetica', 'bold')
    renderCheckboxList(doc, relatorio.fazenda.produtor.Nome, cursor, config)
    doc.setFont('helvetica', 'normal')

    cursor += smallBoxHeight

    renderCheckboxList(doc, 'Propriedade: ', cursor, config)
    cursor += smallGutterY

    doc.setFont('helvetica', 'bold')
    renderCheckboxList(
      doc,
      relatorio.fazenda.pessoajuridica.NomeFantasia,
      cursor,
      config,
    )
    doc.setFont('helvetica', 'normal')

    cursor += smallBoxHeight

    renderCheckboxList(doc, 'Leite coletado dia: ', cursor, config)
    cursor += smallGutterY

    doc.setFont('helvetica', 'bold')
    renderCheckboxList(
      doc,
      relatorio.CrioscopiaDataRecomendacao1Text,
      cursor,
      config,
    )
    doc.setFont('helvetica', 'normal')

    cursor += smallBoxHeight

    renderCheckboxList(
      doc,
      'Resultado da análise de crioscopia: ',
      cursor,
      config,
    )

    cursor += smallGutterY

    let crioscopiaValue
    if (relatorio.CrioscopiaMedida == 1) {
      crioscopiaValue = relatorio.CrioscopiaConstatadaRecomendacao1Text + ' °C'
    } else {
      crioscopiaValue = relatorio.CrioscopiaConstatadaRecomendacao1Text2 + ' °H'
    }

    doc.setFont('helvetica', 'bold')
    renderCheckboxList(doc, crioscopiaValue, cursor, config)
    doc.setFont('helvetica', 'normal')

    cursor += smallBoxHeight

    colInicio = startX + tableLimit / 1000

    if (relatorio.CrioscopiaMedida == 1) {
      data =
        'O índice de crioscópico deve estar entre -0,512°C (quinhentos e doze milésimos de grau Celsius negativos) e \n' +
        'a -0,536°C (quinhentos e trinta e seis milésimos de grau Celsius negativos), respectivamente;\n'
    } else {
      data =
        'O índice de crioscópico deve estar entre -0,530°H (quinhentos e trinta milésimos de grau Hortvet negativos) e \n' +
        'a -0,555°H (quinhentos e cinquenta e cingo milésimos de grau Hortvet negativos), respectivamente;\n'
    }

    var aviso =
      '\nCom este aviso, pedimos a atenção dos envolvidos no processo de produção de leite para que haja a melhoria de qualidade.'

    data += aviso
    const width = doc.getTextWidth(data)
    numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 8)

    cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

    doc.rect(
      startX,
      cursor,
      line,
      smallBoxHeight * numberLines + smallGutterY,
      'F',
    )

    doc.text(data, colInicio + smallGutterX, cursor + smallGutterY, {
      maxWidth: tableLimit - smallGutterX * 2,
    })

    doc.text(
      'Resultado recebido na fazenda por:',
      colInicio + smallGutterX,
      cursor + boxHeight * 3,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )

    doc.setFont('helvetica', 'bold')
    doc.text(
      relatorio.fazenda.produtor.Nome,
      colInicio + smallGutterX + boxHeight * 6,
      cursor + boxHeight * 3,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    doc.setFont('helvetica', 'normal')

    if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
      produtor.src = relatorio.AssinaturaProdutor
      doc.addImage(
        produtor,
        'PNG',
        tableLimit / 2 - 50,
        cursor - 65,
        100,
        145,
        '',
        'FAST',
        270,
      )
    } else {
      produtor.src = relatorio.AssinaturaResponsavel
      doc.addImage(
        produtor,
        'PNG',
        tableLimit / 2 - 50,
        cursor - 65,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
      doc.text(
        'Assinatura do(a) produtor(a)',
        tableLimit / 2 + 25,
        cursor + 170 + smallGutterY,
        null,
        null,
        'center',
      )
    } else {
      doc.text(
        'Assinatura do responsável',
        tableLimit / 2 + 25,
        cursor + 170 + smallGutterY,
        null,
        null,
        'center',
      )
    }

    doc.rect(
      startX + tableLimit,
      cursor,
      line,
      smallBoxHeight * numberLines + smallGutterY,
      'F',
    )

    doc.rect(
      startX,
      cursor + smallBoxHeight * numberLines + smallGutterY,
      tableLimit,
      line,
      'F',
    )
    return cursor + smallBoxHeight * 11
  }
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight + 30

  if (cursor + boxHeight * 10 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  const proximaVisita = relatorio.DataProximaVisita
    ? relatorio.DataProximaVisita
    : ''

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  } else {
    produtor.src = relatorio.AssinaturaResponsavel
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor

  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    doc.text(
      relatorio.NomeResponsavel,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura do responsável',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        var dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 2.8,
          config.startY + config.boxHeight * 6,
          456,
          200,
        )
        var cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 50
        cursor = composicaoRebanho(doc, data, config, cursor)
        config.startY = config.startY + config.boxHeight * 12 + 50
        indicadoresQualidade(doc, data, config)
        config.startY = cursor + config.boxHeight
        cursor = crioscopia(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = declaracao(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-fraude-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
