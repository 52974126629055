<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório de fraude </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGeraisFraude :relatorio="relatorio" />
      <ComposicaoRebanhoFraude :relatorio="relatorio" />
      <IndicadoresQualidadeFraude :relatorio="relatorio" />
      <CrioscopiaFraude :relatorio="relatorio" />
      <DeclaracaoFraude :relatorio="relatorio" />
      <ProximaVisitaFraude :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn color="primary" @click="exportPDF(relatorio)">
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFFraude'
import { exportReport } from '../../../../utils/report'
import ComposicaoRebanhoFraude from './ComposicaoRebanhoFraude'
import CrioscopiaFraude from './CrioscopiaFraude'
import DeclaracaoFraude from './DeclaracaoFraude'
import IndicadoresQualidadeFraude from './IndicadoresQualidadeFraude'
import InformacoesGeraisFraude from './InformacoesGeraisFraude'
import ProximaVisitaFraude from './ProximaVisitaFraude'

export default {
  components: {
    DeclaracaoFraude,
    ComposicaoRebanhoFraude,
    IndicadoresQualidadeFraude,
    InformacoesGeraisFraude,
    CrioscopiaFraude,
    ProximaVisitaFraude,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/boaspraticas`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    exportPDF,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },
    async exportRel(relatorio) {
      if (!relatorio) relatorio = this.relatorio
      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
      })
    },
    alert() {
      this.Swal.fire({
        title: 'Atenção',
        text: 'Em Desenvolvimento!!!',
        icon: 'warning',
      })
    },
  },
}
</script>
