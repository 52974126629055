<template>
  <v-card
    v-if="relatorio.CrioscopiaConstatada || relatorio.CrioscopiaData"
    class="ma-4"
  >
    <v-card-title style="text-align: left">Crioscopia</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.CrioscopiaConstatada == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Crioscopia constatada' }}
          </span>
          <v-col v-if="relatorio.CrioscopiaMedida" cols="12">
            <span v-if="relatorio.CrioscopiaMedida == 1" class="value-title">
              {{ relatorio.CrioscopiaConstatadaRecomendacao1Text + ' °C' }}
            </span>
            <span v-else class="value-title">
              {{ relatorio.CrioscopiaConstatadaRecomendacao1Text2 + ' °H' }}
            </span>
            <br />
          </v-col>
        </v-col>
        <v-col
          v-else-if="relatorio.CrioscopiaConstatada != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Crioscopia constatada' }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.CrioscopiaData == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Data da amostra' }}
          </span>
          <v-col v-if="relatorio.CrioscopiaDataRecomendacao1Text" cols="12">
            <span class="value-title">
              {{ relatorio.CrioscopiaDataRecomendacao1Text }}
            </span>
            <br />
          </v-col>
        </v-col>
        <v-col
          v-else-if="relatorio.CrioscopiaData != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Data da amostra' }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.AguaTubulacao == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Há presença de água na tubulação ou em alguma parte do equipamento antes de iniciar a ordenha'
            }}
          </span>
          <v-col v-if="relatorio.AguaTubulacaoRecomendacao1" cols="12">
            <span class="value-title">
              {{ 'Recomendação:' }}
            </span>
            <br />
            <span class="value-title">
              {{ 'Esgotar toda a água do sistema antes de iniciar a ordenha' }}
            </span>
            <br />
          </v-col>
        </v-col>
        <v-col
          v-else-if="relatorio.AguaTubulacao != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Há presença de água na tubulação ou em alguma parte do equipamento antes de iniciar a ordenha'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.EnxagueDescartado == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O primeiro enxágue após a ordenha é descartado fora do tanque'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.EnxagueDescartado != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O primeiro enxágue após a ordenha é descartado fora do tanque'
            }}
          </span>
          <v-col v-if="relatorio.EnxagueDescartadoRecomendacao1" cols="12">
            <span class="value-title">
              {{ 'Recomendação:' }}
            </span>
            <br />
            <span class="value-title">
              {{ 'Todo o resíduo de enxágue deve ser descartado' }}
            </span>
            <br />
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.CongelamentoLeite == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Funcionamento do tanque está adequado' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.CongelamentoLeite != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Funcionamento do tanque está adequado' }}
          </span>
          <v-col v-if="relatorio.CongelamentoLeiteRecomendacao1" cols="12">
            <span class="value-title">
              {{ 'Recomendação:' }}
            </span>
            <br />
            <span class="value-title">
              {{
                'Entrar em contato com o técnico responsável pela manutenção do tanque'
              }}
            </span>
            <br />
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.DietaBalanceadaRebanho == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'A dieta do rebanho está balanceada' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.DietaBalanceadaRebanho != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A dieta do rebanho está balanceada' }}
          </span>
          <v-col v-if="relatorio.DietaBalanceadaRebanhoRecomendacao1" cols="12">
            <span class="value-title">
              {{ 'Recomendação:' }}
            </span>
            <br />
            <span class="value-title">
              {{
                'Entrar em contato com profissional capacitado para ajustar a dieta'
              }}
            </span>
            <br />
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
