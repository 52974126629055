<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Principais Indicadores</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Produção média (l/dia): </span>
          <span class="value-title">
            {{
              relatorio.ProducaoMedia
                ? relatorio.ProducaoMedia
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">CPP média: </span>
          <span class="value-title">{{
            relatorio.CppMedia
              ? relatorio.CppMedia + ' UFC/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">CCS média: </span>
          <span class="value-title">{{
            relatorio.CcsMedia
              ? relatorio.CcsMedia + ' céls/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title"
            >Número de pessoas envolvidas no manejo de rebanho:
          </span>
          <br />
          <span class="value-title">{{
            relatorio.NumeroMDO ? relatorio.NumeroMDO : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Área para produção de leite (ha): </span>
          <br />
          <span class="value-title"
            >{{
              relatorio.AreaProducao ? relatorio.AreaProducao : 'Não informado'
            }}
            ha</span
          >
        </v-col>
        <v-col cols="12" class="col-bordered">
          <span class="key-title">Produtividade / Área: </span> <br />
          <span class="value-title">{{
            relatorio.ProducaoMedia && relatorio.AreaProducao
              ? `${convertNumberBR(
                  (parseInt(relatorio.ProducaoMedia) * 365) /
                    parseInt(relatorio.AreaProducao),
                )} L / ha / ano`
              : '-'
          }}</span>
        </v-col>
        <v-row
          v-if="
            relatorio.RelevanciaObrigatoria ||
            relatorio.RelevanciaRelevante ||
            relatorio.RelevanciaAceitavel
          "
          id="chart-relevancia"
          class="pa-3"
        >
          <v-col cols="12" sm="6" lg="4" class="col-bordered" align="center">
            <span class="key-title">Conformidades obrigatórias: </span>
            <section>
              <g-chart
                type="PieChart"
                :data="[
                  ['Categoria', 'Quantidade'],
                  ['Realizado', parseInt(relatorio.RelevanciaObrigatoria)],
                  [
                    'Não realizado',
                    100 - parseInt(relatorio.RelevanciaObrigatoria),
                  ],
                ]"
                :options="chartOptionsObrigatorio"
              />
              <p>{{ relatorio.RelevanciaObrigatoria + '%' }}</p>
            </section>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="col-bordered" align="center">
            <span class="key-title">Conformidades relevantes:</span>
            <section>
              <g-chart
                type="PieChart"
                :data="[
                  ['Categoria', 'Quantidade'],
                  ['Conforme', parseInt(relatorio.RelevanciaRelevante)],
                  [
                    'Não Conforme',
                    100 - parseInt(relatorio.RelevanciaRelevante),
                  ],
                ]"
                :options="chartOptionsRelevante"
              />
              <p>{{ relatorio.RelevanciaRelevante + '%' }}</p>
            </section>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="col-bordered" align="center">
            <span class="key-title">Conformidades aceitáveis:</span>
            <section>
              <g-chart
                type="PieChart"
                :data="[
                  ['Categoria', 'Quantidade'],
                  ['Realizado', parseInt(relatorio.RelevanciaAceitavel)],
                  [
                    'Não realizado',
                    100 - parseInt(relatorio.RelevanciaAceitavel),
                  ],
                ]"
                :options="chartOptionsAceitavel"
              />
              <p>{{ relatorio.RelevanciaAceitavel + '%' }}</p>
            </section>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      rotate: -90,
      chartOptionsObrigatorio: {
        legend: 'none',
        chartArea: { height: '80%', width: '50%' },
        tooltip: { isHtml: true, trigger: 'none' },
        responsive: true,
        maintainAspectRatio: false,
        pieHole: 0.85,
        pieSliceText: 'none',
        slices: {
          0: { color: '#dd2c00' },
          1: { color: '#ff6e40' },
        },
      },
      chartOptionsRelevante: {
        legend: 'none',
        chartArea: { height: '80%', width: '50%' },
        tooltip: { isHtml: true, trigger: 'none' },
        responsive: true,
        maintainAspectRatio: false,
        pieHole: 0.85,
        pieSliceText: 'none',
        slices: {
          0: { color: '#ff6f00' },
          1: { color: '#ffe0b2' },
        },
      },
      chartOptionsAceitavel: {
        legend: 'none',
        chartArea: { height: '80%', width: '50%' },
        tooltip: { isHtml: true, trigger: 'none' },
        responsive: true,
        maintainAspectRatio: false,
        pieHole: 0.85,
        pieSliceText: 'none',
        slices: {
          0: { color: '#1b5e20' },
          1: { color: '#43a047' },
        },
      },
    }
  },

  methods: { convertNumberBR },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}

section {
  width: auto;
  height: 200px;
  text-align: center;
  max-width: 400px;
}

section p {
  position: relative;
  margin-top: -35%;
  font-size: 30px;
}
</style>
