<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Composição do rebanho</v-card-title>
    <v-card-text>
      <v-row class="pb-4 mt-1" justify="center">
        <g-chart
          id="chart-rebanho"
          type="PieChart"
          style="height: 35vh; width: 78vh"
          :data="[
            ['Categoria', 'Quantidade'],
            ['Vacas em lactação', relatorio.VacasLactacao],
            ['Vacas secas', relatorio.VacasSecas],
            ['Novilhas', relatorio.Novilhas],
            ['Machos', relatorio.Machos],
            ['Recria', relatorio.Recria],
            ['Aleitamento', relatorio.Aleitamento],
          ]"
          :options="chartOptions"
        />
      </v-row>
      <v-row class="pa-3">
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Vacas em lactação: </span>
          <span class="value-title">{{ relatorio.VacasLactacao }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Vacas secas: </span>
          <span class="value-title">{{ relatorio.VacasSecas }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Novilhas: </span>
          <span class="value-title">{{ relatorio.Novilhas }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Machos: </span>
          <span class="value-title">{{ relatorio.Machos }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Animais em recria: </span>
          <span class="value-title">{{ relatorio.Recria }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Animais em aleitamento: </span>
          <span class="value-title">{{ relatorio.Aleitamento }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Vacas em lactação / total de vacas: </span>
          <span class="value-title">{{ calcLacVacas() }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Vacas em lactação / total do rebanho: </span>
          <span class="value-title">{{ calcLacRebanho() }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Produtividade / vacas em lactação: </span>
          <span class="value-title">{{ calcProdutividade() }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Produtividade / total de vacas: </span>
          <span class="value-title">{{ calcProdutividadeTotal() }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'ComposicaoRebanhoCCS',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          position: 'bottom',
          align: 'center',
        },
        chartArea: { height: '85%', width: '100%' },
        tooltip: { isHtml: true },
      },
    }
  },
  methods: {
    convertNumberBR,
    calcLacVacas() {
      if (this.relatorio.VacasLactacao && this.relatorio.VacasSecas) {
        return `${convertNumberBR(
          100 *
            (parseInt(this.relatorio.VacasLactacao) /
              (parseInt(this.relatorio.VacasLactacao) +
                parseInt(this.relatorio.VacasSecas))),
        )} %`
      } else {
        return '-'
      }
    },
    calcLacRebanho() {
      var totalRebanho = 0
      if (this.relatorio.VacasLactacao) {
        totalRebanho += parseInt(this.relatorio.VacasLactacao)
      }
      if (this.relatorio.VacasSecas) {
        totalRebanho += parseInt(this.relatorio.VacasSecas)
      }
      if (this.relatorio.Novilhas) {
        totalRebanho += parseInt(this.relatorio.Novilhas)
      }
      if (this.relatorio.Recria) {
        totalRebanho += parseInt(this.relatorio.Recria)
      }
      if (this.relatorio.Aleitamento) {
        totalRebanho += parseInt(this.relatorio.Aleitamento)
      }
      if (this.relatorio.Machos) {
        totalRebanho += parseInt(this.relatorio.Machos)
      }

      if (totalRebanho > 0) {
        return `${convertNumberBR(
          100 * (parseInt(this.relatorio.VacasLactacao) / totalRebanho),
        )} %`
      } else {
        return '-'
      }
    },
    calcProdutividade() {
      if (this.relatorio.VacasLactacao && this.relatorio.ProducaoMedia) {
        return `${convertNumberBR(
          parseInt(this.relatorio.ProducaoMedia) /
            parseInt(this.relatorio.VacasLactacao),
        )} L / dia`
      } else {
        return '-'
      }
    },
    calcProdutividadeTotal() {
      var totalVacas = 0
      if (this.relatorio.VacasLactacao) {
        totalVacas += parseInt(this.relatorio.VacasLactacao)
      }
      if (this.relatorio.VacasSecas) {
        totalVacas += parseInt(this.relatorio.VacasSecas)
      }
      if (this.relatorio.ProducaoMedia && totalVacas > 0) {
        return `${convertNumberBR(
          parseInt(this.relatorio.ProducaoMedia) / totalVacas,
        )} L / dia`
      } else {
        return '-'
      }
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
